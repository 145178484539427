import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import useToastMessage from '../../../../../hooks/useToastMessage';
import { WithData } from '../../../../../types/api';
import {
  AddSmartPOSTerminals,
  DevicesCouriers,
  SmartPOSTerminals,
} from '../../../../../types/api/smartPosTerminals';
import { sequential } from '../../../../../utils/helpers/functions';
import { infoToast } from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import { FormFields, toApiData } from './AddEditDialog.functions';
import FormContainer from './FormContainer';

type Props = {
  visible: boolean;
  onHide: () => void;
  data: SmartPOSTerminals | undefined;
  isLoading: boolean;
  isEditDialog: boolean;
  reloadTerminals: () => void;
};

function AddEditDialog({
  isEditDialog,
  visible,
  data,
  onHide,
  reloadTerminals,
  isLoading,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { toastRef } = useContext(ToastContext);

  const { data: devicesCourier, isLoading: isLoadingDevicesCourier } =
    useAxiosHook<DevicesCouriers[]>(
      { url: '/devices' },
      { skipWhen: !visible }
    );

  const {
    data: createData,
    error: createError,
    isLoading: isCreateLoading,
    reload: createReload,
  } = useAxiosHook<AddSmartPOSTerminals>(
    {
      url: '/smart-pos-terminals',
      method: 'POST',
    },
    {
      skipWhen: true,
    }
  );

  useToastMessage(createData, createError, {
    success: {
      summary: t('The terminal has been created successfully.'),
      callback: () => sequential(onHide, reloadTerminals),
    },
    error: {
      summary: t('An error occured while creating terminal.'),
      callback: onHide,
    },
  });

  const {
    data: editData,
    error: editError,
    isLoading: isEditLoading,
    reload: editReload,
  } = useAxiosHook(
    {
      url: `/smart-pos-terminals/${data?.id}`,
      method: 'PUT',
    },
    {
      skipWhen: true,
    }
  );

  useToastMessage(editData, editError, {
    success: {
      summary: t('The terminal has been edited successfully.'),
      callback: () => sequential(onHide, reloadTerminals),
    },
    error: {
      summary: t('An error occured while editing the terminal.'),
      callback: onHide,
    },
  });

  function handleCreation(data: FormFields) {
    createReload({ data: toApiData(data) });
  }

  function handleEditing(data: FormFields) {
    if (!Object.keys(data).length) {
      // If there are no changes introduced, don't contaminate the API
      infoToast(
        toastRef,
        t('No changes made'),
        t("You haven't made any changes yet.")
      );

      return;
    }

    editReload({ data: toApiData(data) });
  }

  const dialogFooter = isLoading ? (
    <></>
  ) : (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={() => onHide()}
        className="p-button-secondary p-button-text"
      />

      <Button
        type="submit"
        form="smart-pos-terminals-create-edit-form"
        label={
          isEditLoading
            ? isEditLoading
              ? t('Updating...')
              : t('Update')
            : isCreateLoading
            ? t('Adding...')
            : t('Add')
        }
        disabled={isEditLoading || isCreateLoading || isEditLoading}
      />
    </>
  );

  const header = isEditDialog
    ? isLoading
      ? t('Loading...')
      : t('Editing {{name}}', { name: data?.username ?? '' })
    : t('Add new') + ' ' + t('terminal');

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={header}
      footer={dialogFooter}
      visible={visible}
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      onHide={onHide}
      style={{ width: 580, maxWidth: '100%' }}
    >
      {isLoading ? (
        <DialogSpinner />
      ) : (
        <FormContainer
          isEditDialog={isEditDialog}
          devicesCourier={devicesCourier}
          isLoadingDevicesCourier={isLoadingDevicesCourier}
          data={data}
          handleCreation={handleCreation}
          handleEditing={handleEditing}
        />
      )}
    </Dialog>
  );
}

export default AddEditDialog;
