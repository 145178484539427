import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../hooks/usePrevious';
import useToastMessage from '../../../../../hooks/useToastMessage';
import { Tariff } from '../../../../../types/api/tariffs';
import { EntityIdRouteParams } from '../../../../../types/routing';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import FieldWithErrors from '../../../../Forms/FieldWithErrors/FieldWithErrors';
import {
  FormFields,
  getDefaultValues,
  getValidationSchema,
} from './AddEditDialog.functions';
import RoleGroupsContext from './RoleGroupsContext';

type Props = {
  isEditDialog: boolean;
  isShown: boolean;
  onHide: () => void;
  reloadTariffs: () => void;
};

function AddEditDialog({
  isEditDialog = false,
  isShown,
  onHide,
  reloadTariffs,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams<EntityIdRouteParams>();
  const formRef = useRef<HTMLFormElement>(null);
  const { toastRef } = useContext(ToastContext);
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const {
    data: tariffData,
    error: tariffError,
    isLoading: isTariffLoading,
  } = useAxiosHook<Tariff>(`/tariffs/${id}`, {
    skipWhen: !isShown || !isEditDialog,
  });

  const defaultValues = useMemo(
    () => getDefaultValues(isEditDialog, tariffData),
    [isEditDialog, tariffData]
  );
  const methods = useForm<FormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const { handleSubmit, control, reset } = methods;

  const {
    data: createTariffData,
    error: createTariffError,
    isLoading: isCreateTariffDataLoading,
    reload: createTariffReload,
  } = useAxiosHook<any>(
    'replace/me', // !!!
    {
      skipWhen: true,
    }
  );

  const prevOrderData = usePrevious(createTariffData);
  const prevOrderError = usePrevious(createTariffError);

  useEffect(() => {
    if (!createTariffData || prevOrderData === createTariffData) {
      return;
    }

    if (createTariffData?.id) {
      successToast(
        toastRef,
        t('Tariff #{{orderID}} Created', { orderID: createTariffData?.id }),
        t('Tariff created sucessfully.')
      );
      reset();
      reloadTariffs();
      onHide();
    }
  }, [
    prevOrderData,
    createTariffData,
    onHide,
    reloadTariffs,
    t,
    toastRef,
    reset,
  ]);

  useEffect(() => {
    if (!createTariffData || createTariffError === prevOrderError) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while trying to delete tariff {{name}}.', {
          name: createTariffData?.name ?? '',
        })
      );
    }

    onHide();
  }, [
    createTariffError,
    onHide,
    createTariffData,
    t,
    toastRef,
    prevOrderError,
  ]);

  useToastMessage(undefined, tariffError, {
    error: {
      summary: t('An error occured while reading price list data.'),
      callback: onHide,
    },
  });

  const dialogFooter = isTariffLoading ? (
    <></>
  ) : (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={() => onHide()}
        className="p-button-text"
      />

      <Button
        type="submit"
        form="price-lists-create-edit-form"
        label={
          isEditDialog
            ? isTariffLoading
              ? t('Updating...')
              : t('Update')
            : isCreateTariffDataLoading
            ? t('Creating...')
            : t('Create')
        }
        disabled={
          isTariffLoading ||
          isTariffLoading ||
          isTariffLoading ||
          isCreateTariffDataLoading
        }
      />
    </>
  );

  const header = isEditDialog
    ? isTariffLoading
      ? t('Loading...')
      : t('Editing {{name}}', { name: tariffData?.name ?? '' })
    : t('Add new') + ' ' + t('tariff');

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  function handleFormSubmission(values: FormFields) {
    console.log('values ', values);
  }

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={header}
      footer={dialogFooter}
      visible={isShown}
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      onHide={onHide}
      style={{ width: 580, maxWidth: '100%' }}
    >
      <FormProvider {...methods}>
        <form
          ref={formRef}
          id="tariff-create-edit-form"
          onSubmit={handleSubmit(handleFormSubmission)}
        >
          <div className="p-fluid">
            <FieldWithErrors name="name" label={t('Name')}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <InputText
                    id="name"
                    name="name"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <div>
                <h1>Rules</h1>
                <RoleGroupsContext />
              </div>
            </FieldWithErrors>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
}

export default AddEditDialog;
