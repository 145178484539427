import citypostLogo from '../assets/img/logos/citypost.png';
import ecologisticLogo from '../assets/img/logos/ecologistic.png';
import koderLogo from '../assets/img/logos/koder.svg';
import kolporterLogo from '../assets/img/logos/kolporter.png';
import logistarLogo from '../assets/img/logos/logistar.png';
import myboxLogo from '../assets/img/logos/MyBox.png';
import novapostaLogo from '../assets/img/logos/novaposta.png';
import postexpressLogo from '../assets/img/logos/postexpress.png';

const logos = {
  koder: koderLogo,
  novaposta: novapostaLogo,
  ecologistic: ecologisticLogo,
  citypost: citypostLogo,
  postexpress: postexpressLogo,
  kolporter: kolporterLogo,
  logistar: logistarLogo,
  mybox: myboxLogo,
};

export default logos;
