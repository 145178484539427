import { faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { isBoolean } from 'lodash';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch, InputSwitchChangeParams } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryAutoCompleteInputParam from '../../../hooks/useSearchQueryAutoCompleteInputParam';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import {
  ClientLookupCollection,
  ClientLookupCollectionQueryParams,
  ClientResource,
} from '../../../types/api/clients';
import {
  ImportListTypeCollection,
  ImportListTypeResource,
} from '../../../types/api/importlisttypes';
import * as importedListsGuards from '../../../utils/constants/auth/importedLists';
import { debounceTimeout } from '../../../utils/constants/misc';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { queryString } from '../../../utils/helpers/http';
import { renderIcon } from '../../../utils/helpers/icon';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';
import AutoCompleteInput from '../../Forms/AutoCompleteInput/AutoCompleteInput';
import FiltersCounter from '../Components/Filters/FiltersCounter';
import { ClientFilterOption, ListTypeFilterOption } from './BulkOrders.types';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const clientFilterGuard = useEndpointGuard(importedListsGuards.clientFilter);
  const listTypeFilterGuard = useEndpointGuard(
    importedListsGuards.listTypeFilter
  );
  const location = useLocation();
  const [clientFilter, setClientFilter] = useState('');
  const [clientFilterObj, setClientFilterObj] =
    useState<ClientFilterOption | null>(null);
  const [listTypeFilter, setListTypeFilter] = useState('');
  const [listTypeFilterObj, setListTypeFilterObj] =
    useState<ListTypeFilterOption | null>(null);
  const [nameFilter, setNameFilter] = useState(
    () => getSearchQueryParam(location.search, 'name') ?? ''
  );
  const [dateFrom, setDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? new Date()
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date()
  );
  const [barcodeFilter, setBarcodeFilter] = useState(
    () => getSearchQueryParam(location.search, 'barcode') ?? ''
  );
  const [shipmentBarcodeFilter, setShipmentBarcodeFilter] = useState(
    () => getSearchQueryParam(location.search, 'shipment_barcode') ?? ''
  );
  const [statusFilter, setStatusFilter] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'status') ?? null
  );
  const [importLog, setImportLog] = useState<boolean | any>(
    () => getSearchQueryParam(location.search, 'import_log') ?? null
  );
  const [senderName, setSenderName] = useState<string>(
    () => getSearchQueryParam(location.search, 'sender_name') ?? ''
  );
  const debouncedNameFilter = useDebounce(nameFilter, debounceTimeout);
  const debouncedClientFilter = useDebounce(clientFilter, debounceTimeout);
  const debouncedListTypeFilter = useDebounce(listTypeFilter, debounceTimeout);
  const debouncedBarcodeFilter = useDebounce(barcodeFilter, debounceTimeout);
  const debouncedSenderName = useDebounce(senderName, debounceTimeout);
  const debouncedShipmentBarcodeFilter = useDebounce(
    shipmentBarcodeFilter,
    debounceTimeout
  );

  const { data: clientData } = useAxiosHook<ClientLookupCollection>(
    '/clients/lookup' +
      queryString<ClientLookupCollectionQueryParams>({
        ime: debouncedClientFilter,
        page: 1,
        limit: 10,
      }),
    { skipWhen: !clientFilterGuard }
  );
  const { data: listTypeData } = useAxiosHook<ImportListTypeCollection>(
    '/importlisttypes' +
      queryString({ name: debouncedListTypeFilter, page: 1, limit: 10 }),
    { skipWhen: !listTypeFilterGuard }
  );
  const clientOptions = useMemo(
    () =>
      clientData?.data.map((client) => {
        return {
          label: client.ime,
          value: client,
        };
      }) ?? [],
    [clientData?.data]
  );
  const listTypeOptions = useMemo(
    () =>
      listTypeData?.data.map((listType) => {
        return {
          label: listType.name,
          value: listType,
        };
      }) ?? [],
    [listTypeData?.data]
  );

  const statusFilterOptions = useMemo(
    () => [
      { label: t('Processed'), value: 'processed' },
      { label: t('Processing'), value: 'processing' },
      { label: t('Processed With Warnings'), value: 'processed_with_warnings' },
      { label: t('Processed With Errors'), value: 'processed_with_errors' },
      { label: t('Failed'), value: 'failed' },
    ],
    [t]
  );

  const filtersArr = useMemo(
    () => [
      dateFrom,
      dateTo,
      statusFilter,
      clientFilterObj,
      listTypeFilterObj,
      debouncedNameFilter,
      debouncedBarcodeFilter,
      debouncedSenderName,
    ],
    [
      clientFilterObj,
      dateFrom,
      dateTo,
      debouncedBarcodeFilter,
      debouncedNameFilter,
      listTypeFilterObj,
      statusFilter,
      debouncedSenderName,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [
    setPage,
    dateFrom,
    dateTo,
    statusFilter,
    clientFilterObj,
    listTypeFilterObj,
    debouncedNameFilter,
    debouncedBarcodeFilter,
    debouncedSenderName,
  ]);

  useSearchQueryParam('name', debouncedNameFilter);
  useSearchQueryParam('barcode', debouncedBarcodeFilter);
  useSearchQueryParam('shipment_barcode', debouncedShipmentBarcodeFilter);
  useSearchQueryDateParam('date_from', dateFrom);
  useSearchQueryDateParam('date_to', dateTo);
  useSearchQueryParam('sender_name', debouncedSenderName);
  useSearchQueryAutoCompleteInputParam<ClientFilterOption, ClientResource>({
    param: 'client_id',
    filterValue: clientFilter,
    setFilterValue: setClientFilter,
    filterValueObj: clientFilterObj,
    setFilterValueObj: setClientFilterObj,
    axiosRequestConfig: (clientId) => `/clients/${clientId}`,
    initialFilterValueDataPath: 'ime',
    filterValueObjPath: 'id',
    initialDataModifier: (data) => ({ id: data.id }),
  });
  useSearchQueryAutoCompleteInputParam<
    ListTypeFilterOption,
    ImportListTypeResource
  >({
    param: 'listType',
    filterValue: listTypeFilter,
    setFilterValue: setListTypeFilter,
    filterValueObj: listTypeFilterObj,
    setFilterValueObj: setListTypeFilterObj,
    axiosRequestConfig: (listTypeId) => `/importlisttypes/${listTypeId}`,
    initialFilterValueDataPath: 'name',
    filterValueObjPath: 'id',
    initialDataModifier: (data) => ({ id: data.id }),
  });
  useSearchQueryDropdownParam(
    'status',
    statusFilter,
    setStatusFilter,
    statusFilterOptions
  );

  useEffect(() => {
    if (!clientFilterGuard) {
      setClientFilter('');
      setClientFilterObj(null);
    }
  }, [clientFilterGuard]);

  useEffect(() => {
    if (!listTypeFilterGuard) {
      setListTypeFilter('');
      setListTypeFilterObj(null);
    }
  }, [listTypeFilterGuard]);

  const httpFiltersObj = useMemo(
    () => ({
      client_id: clientFilterObj?.id,
      client_name: !clientFilterObj ? debouncedClientFilter : undefined,
      name: debouncedNameFilter,
      import_list_type_id: listTypeFilterObj?.id,
      barcode: debouncedBarcodeFilter,
      shipment_serial_number: debouncedShipmentBarcodeFilter,
      created_date_from: dateFrom ? httpDateFormat(dateFrom) : undefined,
      created_date_to: dateTo ? httpDateFormat(dateTo) : undefined,
      status_tip: statusFilter,
      sender_name: debouncedSenderName,
      import_log: importLog ? 1 : null,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      clientFilterObj,
      dateFrom,
      dateTo,
      debouncedBarcodeFilter,
      debouncedClientFilter,
      debouncedNameFilter,
      debouncedShipmentBarcodeFilter,
      haveFiltersChanged,
      limit,
      listTypeFilterObj?.id,
      importLog,
      page,
      statusFilter,
      debouncedSenderName,
    ]
  );

  const basicFiltersActiveFilterCount = Object.values({
    dateFrom, // date_from and date_to is count like one
    clientFilter,
    nameFilter,
    listTypeFilter,
    barcodeFilter,
    shipmentBarcodeFilter,
    statusFilter,
    importLog,
    senderName,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_date_from">{t('Created Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                value={dateFrom ?? undefined}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                inputId="bulk-orders-filter_date_from"
                className={classNames({
                  sidebar_filter_active: dateFrom,
                })}
                onChange={(e) => {
                  setDateFrom(e.value as Date | null);
                }}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                value={dateTo ?? undefined}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                inputId="bulk-orders-filter_date_to"
                className={classNames({
                  sidebar_filter_active: dateTo,
                })}
                onChange={(e) => {
                  setDateTo(e.value as Date | null);
                }}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_imported_list_name">{t('Name')}</label>
            <InputText
              id="filter_imported_list_name"
              value={nameFilter}
              className={classNames({
                sidebar_filter_active: nameFilter,
              })}
              onChange={(e) => setNameFilter(e.target.value)}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_barcode">{t('List Barcode')}</label>
            <InputText
              id="filter_barcode"
              value={barcodeFilter}
              className={classNames({
                sidebar_filter_active: barcodeFilter,
              })}
              onChange={(e) => setBarcodeFilter(e.target.value)}
            />
          </div>
          {clientFilterGuard && (
            <div className="filter filters">
              <label htmlFor="filter_client">{t('Client')}</label>
              <AutoCompleteInput
                id="filter_client"
                filterValue={clientFilter}
                value={clientFilterObj}
                options={clientOptions}
                forceSelection={false}
                onFilterChange={setClientFilter}
                onSelectionChange={setClientFilterObj}
                className={classNames({
                  sidebar_filter_active: clientFilterObj,
                })}
              />
            </div>
          )}
          {listTypeFilterGuard && (
            <div className="sidebar_filter">
              <label htmlFor="filter_list_type">
                {t('Batch Order Template')}
              </label>
              <AutoCompleteInput
                id="filter_list_type"
                filterValue={listTypeFilter}
                value={listTypeFilterObj}
                options={listTypeOptions}
                onFilterChange={setListTypeFilter}
                onSelectionChange={setListTypeFilterObj}
                className={classNames({
                  sidebar_filter_active: listTypeFilterObj,
                })}
              />
            </div>
          )}
          <div className="sidebar_filter">
            <label htmlFor="status_filter">{t('Status')}</label>
            <Dropdown
              name="status_filter"
              id="status_filter"
              options={statusFilterOptions}
              value={statusFilter}
              showClear
              className={classNames({
                sidebar_filter_active: statusFilter,
              })}
              onChange={(e) => setStatusFilter(e.value)}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="shipment_barcode">{t('Shipment Barcode')}</label>
            <InputText
              id="shipment_barcode"
              value={shipmentBarcodeFilter}
              className={classNames({
                sidebar_filter_active: shipmentBarcodeFilter,
              })}
              onChange={(e) => setShipmentBarcodeFilter(e.target.value)}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="sender_name">{t('Sender Name')}</label>
            <InputText
              id="sender_name"
              value={senderName}
              className={classNames({
                sidebar_filter_active: senderName,
              })}
              onChange={(e) => setSenderName(e.target.value)}
            />
          </div>
          {shipmentBarcodeFilter.length > 0 && (
            <div className="sidebar_filter">
              <label htmlFor="import_log_filter">
                {t('Include address books from failed imports')}
              </label>
              <InputSwitch
                id="import_log_filter"
                checked={isBoolean(importLog) ? importLog : null}
                onChange={(e: InputSwitchChangeParams) =>
                  setImportLog(Boolean(e.value))
                }
                className={classNames({
                  sidebar_filter_active: importLog,
                })}
              />
            </div>
          )}
        </AccordionTab>
      </Accordion>
    ),
    [
      barcodeFilter,
      clientFilter,
      clientFilterGuard,
      clientFilterObj,
      clientOptions,
      dateFrom,
      dateTo,
      listTypeFilter,
      listTypeFilterGuard,
      listTypeFilterObj,
      listTypeOptions,
      nameFilter,
      shipmentBarcodeFilter,
      statusFilter,
      statusFilterOptions,
      basicFiltersActiveFilterCount,
      importLog,
      senderName,
      t,
    ]
  );

  function resetAllFilters() {
    setDateFrom(new Date());
    setDateTo(new Date());
    setNameFilter('');
    setBarcodeFilter('');
    setShipmentBarcodeFilter('');
    setClientFilter('');
    setClientFilterObj(null);
    setListTypeFilter('');
    setListTypeFilterObj(null);
    setStatusFilter(null);
    setSenderName('');
  }

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
